<template>
  <div>
    <div class="list-group array-box__items">
      <a
        class="list-group-item list-group-item-action cursor-pointer"
        href.prevent=""
        v-for="item of items"
        v-bind:key="item"
        @click="removeItem(item)"
      >
        <span>{{ item }}</span>
        <i class="fa fa-trash text-danger float-right pt-1"></i>
      </a>
      <div class="list-group-item">
        <input
          class="form-control"
          placeholder="Write something then press enter..."
          v-model="input"
          @keyup.enter.prevent="addItem()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:["initialData", "hasInitData"],

  data() {
    return {
      input: "",
      items: [],
    };
  },

  // mounted() {
  //   if (this.initialData) {
  //     this.items = this.initialData;
  //     this.$emit("update", this.items);
  //   }
  // },
  watch: {
    hasInitData: {
      immediate: true,
      handler (val, oldVal) {
        if(val){
          this.items = this.initialData;
          this.$emit("update", this.items);
        }

      }
    }
  },

  methods: {
    addItem() {
      this.items.push(this.input);
      this.input = "";
      this.$emit("update", this.items);
    },

    removeItem(item) {
      if (confirm("Do you want to remove this item?")) {
        this.items = this.items.filter((el) => el != item);
        this.$emit("update", this.items);
      }
    },
  },
};
</script>

<style scoped>
.array-box__items a {
  padding: 7px 20px;
}
</style>