<template>
  <div>
    <slot :clicked="showTranslateModal">

    </slot>
    <modal name="translate-modal" height="auto">
      <div class="p-4">
        <div class="h3 mb-3">Add new translate </div>
        <form action="">
          <div v-if="items && !model_id" class="form-group col-lg-12">
            <label>{{model}}</label>
            <select class="form-control" v-model="translate.model_id">
              <option disabled value="">Which one you want add translate</option>
              <option
                  v-for="item in items"
                  :value="item.id"
              >{{ item.title }}</option>
            </select>
          </div>
          <div class="form-group col-lg-12">
            <label>Language</label>
            <select @change="getTranslation" class="form-control" v-model="translate.language">
              <option disabled value="">Select language</option>
              <option
                  v-for="item in languages"
                  :value="item.code"
              >{{ item.title }}</option>
            </select>
          </div>
          <div class="col-lg-12"
          v-for="item in keys"
          >
            <label>{{item.key}}</label>
            <input
                v-if="item.type === 'text'"
                v-model="translate.translatable[item.key]"
                :type="item.type"
                class="form-control"
                :placeholder="item.key"
            />
            <vue-editor
                v-else-if="item.type === 'editor'"
                v-model="translate.translatable[item.key]"
                :placeholder="item.key"
            ></vue-editor>
            <array-box v-else-if="item.type === 'array'" :has-init-data="translate.translatable[item.key].length > 0" :initial-data="translate.translatable[item.key]" @update="(arr) => (translate.translatable[item.key] = arr)"></array-box>
          </div>



          <ul style="padding-left: 20px; margin-top: 20px">
            <li
                v-for="error in translate.errors"
                v-bind:key="error"
                class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button class="btn btn-primary mt-3 w-100" type="button" @click="saveTranslate">
            Save
          </button>
        </form>
      </div>
    </modal>
  </div>

</template>
<script>
import {config} from "@/helpers";
import Vue from "vue";
import { VueEditor } from "vue2-editor";

import ArrayBox from "@/components/ArrayBox.vue";

export default {
  name: 'Translator',
  components: {ArrayBox,  VueEditor},
  data(){
    return {
      languages: [],
      translation : {},
      translate: {
        translatable: {},
        model: "",
        model_id: "",
        language: "",

      },
    }
  },
  props: {
    items: {
      type: Array,
      required: false
    },
    keys: {
      type: Array,
      required: true,
    },
    model_id: {
      type: Number,
      required: false,
    },
    model: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.getLanguages()
    this.generateTranslatable()

  },
  methods: {
    getLanguages(){
      axios.get(config("BACKEND_ROOT") + "/admin/languages").then((response) => {
        this.languages = response.data.data;
      });
    },

    async getTranslation() {
      this.translation = [];
      await axios.get(config("BACKEND_ROOT") + `/admin/translate/${this.model}/${this.model_id ?? this.translate.model_id}/${this.translate.language}`).then((response) => {
        this.translation = response.data.data;
      });
      this.translation.forEach(item => {
        const currentKey =  this.keys.filter(key => key.key === item.key)[0];

        if(currentKey.type === 'array'){
          //the last programmer(shame on they 😡😡) saved highlight that is array for collections(specially) with text,
          //and they Separated text with \n to show array on website 🤦‍♂️🤦‍♂️
          // for fix that on load translation I have to check the fist character of highlight starts with [ or not
          //because I saved array of highlight on translations table with array that starts with [
          //so I have to find out the coming text is array or not(at least until the all saved text be update)
          //if all texts updated and I forgot to remove this shit code, forgive me🙏
          //hopefully the future of programmers find a solution for this 🤲🤲
          let valueArr = [];
          if(item.value[0] === "["){
              valueArr = JSON.parse(item.value);
          }
          else{
             valueArr = item.value.split("\n");
          }
          for(let i = 0; i < valueArr.length; i++){
            this.translate.translatable[item.key][i] = valueArr[i];
          }
        }
        else{
          this.translate.translatable[item.key] = item.value;
        }

      });

    },

    showTranslateModal(){
      this.$modal.show("translate-modal");
    },
    saveTranslate() {
      if(! this.items && this.model_id){
        this.translate.model_id = this.model_id;
      }
      this.translate.model = this.model;
      event.preventDefault();
      axios
          .post(config("BACKEND_ROOT") + "/admin/translate", this.translate)
          .then((response) => {
            Vue.notify({
              group   : 'notify',
              type    : 'success',
              title   : 'Hooray',
              duration: 1000,
              text    : "Data Saved"
            });
            setTimeout(function () {
              location.reload();
            }, 2000)
          })
          .catch((error) => {
            Vue.notify({
              group   : 'notify',
              type    : 'error',
              title   : 'Error!',
              duration: 5000,
              text    : Object.values(
                  error.response.data.errors
              ).flat()
            });

          });
    },
    generateTranslatable(){
      this.keys.forEach(item => {
        this.$set(this.translate.translatable, item.key, item.type === "text" ? "" : [])
      })
      console.log(this.translate);
    }
  }
}
</script>
<style scoped>
.add-item {
  color: #666;
  border: 2px dashed #999;
  padding-top: 17px;
  transition: 0.3s !important;
}
.add-item:hover {
  color: #f42f4b;
  border-color: #f42f4b;
}
</style>