<template>
  <div class="w-100">
    <h1 class="mb-4">Edit meta data</h1>

    <div class="form">
      <label for="">Type:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <select v-model="subjectType" @change="loadSubjects()" class="form-control">
            <option v-for="type in subjectTypes" :value="type.title" v-bind:key="type.title">{{ type.title }}</option>
          </select>
        </div>
      </div>
      <label for="">Item:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <select v-model="subjectId" class="form-control">
            <option v-for="subject in subjects" :value="subject.id" v-bind:key="subject.id">{{ subject.title }}</option>
          </select>
        </div>
      </div>

      <div v-if="this.subjectId != null" class="mb-4">
        <button class="btn btn-primary" @click ="generateMetaData">Generate metadata with AI</button>

        <div v-if="metaDataLoading">Generating metadata with chat gpt...</div>
      </div>

      <label for="">Meta Title:</label>
      <div class="form-row">
        <div class="form-group col-lg-12">
          <input
            v-model="form.title"
            class="form-control"
            placeholder="Title"
          />
        </div>
      </div>

      <label for="">Meta description:</label>
      <div class="form-row">
        <div class="form-group col-lg-12">
          <input
            v-model="form.description"
            class="form-control"
            placeholder="Description"
          />
        </div>
      </div>

      <ul style="padding-left: 20px; margin-top: 20px">
        <li v-for="error in errors" v-bind:key="error" class="text-danger">
          {{ error }}
        </li>
      </ul>

      <button
        type="submit"
        class="btn btn-primary pl-4 pr-4"
        @click="submit()"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { config } from "../../../helpers";
import metadataOptions from "@/static/metadata.json"

export default {
  name: "MetaDataEdit",
  data() {
    return {
      id: this.$route.params.id,

      subjectTypes: metadataOptions,

      subjects: [],
      selectedSubject: {},

      subjectType: null,
      subjectId: null,
      metaDataLoading: false,


      form: {
        title: null,
        description: null,
      },
      errors: [],
    };
  },

  mounted() {
    this.loadMetaData();
  },

  methods: {
    loadMetaData() {

      axios
        .get(`${config("BACKEND_ROOT")}/admin/meta-data/${this.id}`)
        .then(async response => {
          this.subjectType = response.data.data.subject_type;

          await this.loadSubjects();
          this.form.title = response.data.data.title;
          this.form.description = response.data.data.description;
          this.subjectId = response.data.data.subject_id;
          this.subjectType = response.data.data.subject_type
          if(response.data.data.subject)
            this.subjects.push(response.data.data.subject);
        })
    },

    loadSubjects() {

      this.subjectTypes.forEach((subject) => {
        if(this.subjectType == subject.title){
          this.selectedSubject = subject;
        }
      });

      this.subjects = [];
      if(this.selectedSubject.value != 0){
        if(this.selectedSubject.extra_data){
          this.subjects.push(this.selectedSubject.extra_data);
        }

      }
      else{
        this.subjects.push({'id' : this.selectedSubject.value, 'title': this.selectedSubject.title});
      }
    },

    async generateMetaData() {
      this.metaDataLoading = true;

      var type = this.subjectType;
      if (this.subjectType === 'home'){
        var id = 0;
      }else{
        id = this.subjectId;
      }

      axios.post(`${config("BACKEND_ROOT")}/admin/meta-data/${type}/${id}/ai`).then((response) => {
        var res = response.data.data;
        this.form.title = res.title;
        this.form.description = res.description;
        this.metaDataLoading = false;
      }).catch((error) => {
        console.error('Error:', error);
      });
    },

    submit() {
      axios
        .post(`${config("BACKEND_ROOT")}/admin/meta-data/${this.subjectType}/${this.subjectId}`, this.form)
        .then(response => {
          this.$router.push("/meta-data");
        })
        .catch(error => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },
  },
};
</script>
